:root {
  --green1: #00C645;
  --green2: #006838;
  --green3: #62D38F;
  --yellow1: #FFEC39;
  --blue1: #06C5F9;
}
body {
  margin: 0;
}
