.userInfoWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.userInfoWrapper>div {
    border: 1px solid var(--yellow1);
    padding: 8px;
    border-radius: 100px;
    background-color: var(--green2);
}

.userInfoWrapper>button {
    border-radius: 100px;
}

.financialStatus {
    display: flex;
    gap: 8px;
    justify-content: start;
    padding: 0 1rem;
}
