.lotto-section {
    margin: 10px;
    padding: 10px;
    background-color: #f4fcff;
    /* position: relative; */
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.lotto-card {
    /* position: relative; */
    padding: 10px;
    /* margin: 10px; */
    background-color: #fff;
    border: 1px solid #F8F2F3;
    border-radius: 5px;
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
}

.lotto-card .header {
    padding: 5px;
    margin-bottom: 5px;
    /* background-color: #fff; */
    border: 1px solid #F8F2F3;
    border-radius: 5px;
    display: inline-flex;
    width: 100%;
    /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s; */
}

.lotto-card .content-card {
    /* padding: 10px 5px; */
    /* background-color: #fff; */
    border: 1px solid #F8F2F3;
    border-radius: 5px;
    padding: 5px;
    display: inline-flex;
    width: 100%;
    /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s; */
}

.lotto-card .time {
    /* padding: 10px 5px; */
    /* background-color: #fff; */
    border: 1px solid #F8F2F3;
    border-radius: 5px;
    padding: 5px;
    margin-top: 5px;
    /* display: block; */
    width: 100%;
    /* -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s; */
}

.lotto-card .time .time-content {
    /* padding: 5px; */
    display: inline-flex;
    width: 100%;
}

.announce-section {
    margin: 0 0 1rem 0;
    /* padding: 10px; */
    background-color: white;
    /* position: relative; */
    border: 1px solid var(--green3);
    -webkit-box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px rgba(72, 73, 121, 0.15);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;
}

.announce-section .announce-header {
    padding: 10px 10px 0px 20px;
    /* margin-bottom: 5px; */
    background-color: var(--green3);
    border: 1px solid transparent;
    /* display: block; */
    width: 100%;
}

.announce-section .announce-content {
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    /* border-radius: 0px 0px 5px 5px; */
    /* display: block; */
    width: 100%;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
}

.announce-section .announce-content section {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.announce-section .announce-content section * {
    font-size: 1.25rem;
}